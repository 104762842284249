import {VariableStates} from './variable-states';
// import {initMockFileSystem} from '@angular/compiler-cli/src/ngtsc/file_system/testing';
import {EventEmitter} from '@angular/core';



export class ComponentVariable {
  public FeatureName: string;
  public Name: string;
  public Parameter: string;
  public UAPath: string;
  public Typ: string;
  public VariableType: string;
  public CurrentValue: any;
  public _defaultValue: any;
  public MaxValue: any;
  public MinValue: any;
  public StepSize = 1.0;
  public _directionDependency = false;
  public ConstallationDependency = false;
  public Unit: string;
  public Context: string;
  public TranslationId: string;
  public UnitTranslationId: string;
  public DefaultName: string;
  public States: VariableStates[];
  public Text: string;
  public Mode: string;
  public DesignRelevant: boolean;
  public DisplayPath: string;
  public ValuePath: string;
  public SelectionList: any[];
  public VariableValueChanged = new EventEmitter<any>();
  public Deactivated: any[] = [];
  public DownloadPossible = false;
  public UploadPossible = false;
  public Excluded = false;
  public Visible = true;
  public Virtual = false;
  public ShowPositiveOnly = false;
  public RecipeDirection = 1;

  Direction = 1;

  public set DirectionDependency(val) {
    this._directionDependency = val;
  }

  public get DirectionDependency(): boolean {
    return this._directionDependency;
  }

  public set DefaultValue(val) {


    if (this.States.length === 0) {
      if (this._directionDependency === true) {
        if (val < 0) {
          this._defaultValue = val;
          this.CurrentValue = val;
          this.Direction = -1;
        } else {
          this._defaultValue = val;
          this.CurrentValue = val;
        }
      } else {
        this._defaultValue = val;
        this.CurrentValue = val;
      }
    } else {
      if (this.States.find(ex => ex.State === val)) {
        this._defaultValue = val;
        this.CurrentValue = val;
      }
    }
  }

  public get DefaultValue(): any {
    return this._defaultValue;
  }

  constructor(name: string, parameter: string, component: string, context: string, module: string, uaPath: string) {
    this.States = [];
    this.Name = name;
    this.Text = name;
    this.Parameter = parameter;
    this.Context = context;
    this.UAPath = uaPath;
    this.TranslationId = 'MODULES.COMPONENTS.' + component + '.PARAMETERS.' + name.toUpperCase();
    this.CurrentValue = null;

    if (this.UAPath.indexOf('{ComponentName}') >= 0) {
      this.UAPath = this.UAPath.replace('{ComponentName}', component);
    }
    if (this.UAPath.indexOf('{Context}') >= 0) {
      this.UAPath = this.UAPath.replace('{Context}', context);
    }
  }

  public getUnitId() {
    if (this.Unit) {
      return 'MODULES.UNITS.' + this.Unit.toUpperCase();
    } else {
      return null;
    }
  }

  public GetName(): string {
    return this.Text;
  }

  public SetValue(value, checkBoundaries = true): boolean {
    if (this._directionDependency === true) {
      if (value < 0) {
        this.Direction = -1;
        this.MinValue = Math.abs(this.MinValue) * this.Direction;
        this.MaxValue = Math.abs(this.MaxValue) * this.Direction;
      } else {
        this.Direction = 1;
        this.MinValue = Math.abs(this.MinValue) * this.Direction;
        this.MaxValue = Math.abs(this.MaxValue) * this.Direction;
      }
    }
    let valueSet = false;

    if (this.States.length > 0) {

      if (this.VariableType === 'BooleanSwitch') {
        if ( value !== true && value !== false) {
          if (value === 1) {
            value = true;
          } else {
            value = false;
          }
        }
      }
      if (this.States.find(ex => ex.State === value)) {
        this.CurrentValue = value;
        valueSet = true;
      } else {
        return false;
      }
    } else {

      if (checkBoundaries) {

        if (this.MinValue) {
          if (value < this.MinValue) {
            return false;
          }
        }

        if (this.MaxValue) {
          if (value > this.MaxValue) {
            return false;
          }
        }
        this.CurrentValue = value;
        valueSet = true;



      }  else {
        this.CurrentValue = value;
        valueSet = true;
      }
    }

    if (valueSet && !this.Virtual) {
      this.VariableValueChanged.emit({
        variable: this.Name,
        value: this.CurrentValue
      });
    }
    return true;
  }



  public forceValueChanged() {
    this.VariableValueChanged.emit({
      variable: this.Name,
      value: this.CurrentValue
    });
  }

  public SetAdditionalInfo(info: string) {
    if (this.VariableType === 'SemiColonSeparatedArray') {
      const spli = info.split(';');
      if (spli.length > 0) {
        let startNumber = 1;
        this.SelectionList = [];

        for (const val of spli) {
          this.SelectionList.push({
            Number: startNumber,
            Name: val
          });
          startNumber = startNumber + 1;
        }
        // ADD STATES
        this.States = [];

        for (const sl of this.SelectionList) {
          const sts = sl[this.DisplayPath];

          const state = new VariableStates(sts, '', '', sts);
          // state.DefaultText = sts;

          this.States.push(state);
        }
      }
    }
  }

  public getValue(): any {
    if (this.UAPath == 'MMI.SZ') {
      // console.log(this.CurrentValue);
    }

    if (this.DisplayPath && this.ValuePath) {
      if (this.CurrentValue !== null && this.CurrentValue !== undefined) {
        for (const l of this.SelectionList) {
          if (l[this.DisplayPath] === this.CurrentValue) {
            return l[this.ValuePath];
          }
        }
        return null;
      } else {
        return null;
      }
    } else {

      if (this.Typ === 'String') {
        return '"' +this.CurrentValue + '"';
      } else {
        return this.CurrentValue;
      }


    }
  }

  public Copy(): ComponentVariable {
    const v = new ComponentVariable(this.Name, this.Parameter, null, this.Context, null, this.UAPath);
    v.Virtual = this.Virtual;
    v.MaxValue = this.MaxValue;
    v.MinValue = this.MinValue;
    v.States = this.States;
    v.Direction = this.Direction;
    v.DefaultName = this.DefaultName;
    v.TranslationId = this.TranslationId;
    v.DirectionDependency = this.DirectionDependency;
    v.ConstallationDependency = this.ConstallationDependency;
    v.Unit = this.Unit;
    v.VariableType = this.VariableType;
    v.Typ = this.Typ;
    v.FeatureName = this.FeatureName;
    v.DefaultValue = this.DefaultValue;
    v.StepSize = this.StepSize;
    v.CurrentValue = this.CurrentValue;
    v.Text = this.Text;
    v.Mode = this.Mode;
    v.DesignRelevant = this.DesignRelevant;
    v.DisplayPath = this.DisplayPath;
    v.SelectionList = this.SelectionList;
    v.ValuePath = this.ValuePath;
    v.Deactivated = this.Deactivated;
    v.DownloadPossible = this.DownloadPossible;
    v.UploadPossible = this.UploadPossible;
    v.Excluded = this.Excluded;
    v.Visible = this.Visible;
    v.ShowPositiveOnly = this.ShowPositiveOnly;
    v.RecipeDirection = this.RecipeDirection;

    return v;
  }

}
