import { Injectable, EventEmitter } from '@angular/core';
import {WebsocketService} from './websocket.service';
import {ContextTypes} from '../../classes/enums/context-types.enum';
import {MessageTypes} from '../../classes/enums/message-types.enum';
import {Entities} from '../../classes/enums/entities.enum';
import {VERSION} from '../../../environments/version';
import {NetworkDeviceTypes} from '../../classes/enums/network-device-types.enum';
import {environment} from '../../../environments/environment';
import {DemoHandlingService} from '../demo-handling.service';
import {CustomerModule} from '../../classes/customer-module';
import {ModuleComponent} from '../../classes/module-component';

@Injectable({
  providedIn: 'root'
})
export class MessageHandlingService {

  // TODO: 15. Module Down-/Upload (Binder)

  // MODULE EVENTS
  public ClusterRequestResponse = new EventEmitter<any>();
  public ClusterResetResponse = new EventEmitter<any>();
  public ModulesStoppedMessage = new EventEmitter<string>();
  public ClusterConfigResponse = new EventEmitter<any>();
  public ModuleInitResponse = new EventEmitter<any>();
  public ModuleModeChanged = new EventEmitter<any>();
  public WrongModuleModeReceived = new EventEmitter<any>();
  public MonitoringValuesReceived = new EventEmitter<any>();
  public ModuleStateChanged = new EventEmitter<any>();
  public ModuleOnlineStateChanged = new EventEmitter<any>();
  public ModuleConfigResponse = new EventEmitter<any>();
  public EmergencyTestStateChanged = new EventEmitter<any>();
  public InternalBeltConveyorChanged = new EventEmitter<any>();
  public LevelingInformationReceived = new EventEmitter<any>();
  public LevelingRequiredChanged = new EventEmitter<any>();
  public ModuleMessageReceived = new EventEmitter<any>();

  public ModuleParameterChangeRequest = new EventEmitter<any>();
  public UpdateSpecificationResponse = new EventEmitter<any>();

  // CLUSTER EVENTS
  public ClusterInitReponse = new EventEmitter<any>();
  public SafetyFinishedMessage = new EventEmitter<string>();
  public NotificationsReceived = new EventEmitter<any>();
  public RecipeReceived = new EventEmitter<any>();
  public RecipeUpdated = new EventEmitter<any>();
  public ClusterRevisionReceived = new EventEmitter<any>();

  //17.1.
  public ModuleOnboardingLogMessage = new EventEmitter<any>();
  public ClusterOnboardingLogMessage = new EventEmitter<any>();



  constructor(private websocket: WebsocketService,
              private demoHandling: DemoHandlingService) {
    this.websocket.WebsocketMessage.subscribe(this.ReceiveMessage.bind(this));
  }

  private ReceiveMessage(msg: any) {
    try {

      const jmsg = JSON.parse(msg);

      if (jmsg.context === ContextTypes.CLUSTER) {
        // 1.9. Cluster Init Reponse
        if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.INIT) {
          this.ClusterInitReponse.emit(jmsg);
        }

        // 1.10. Sicherheitskonfiguration abgeschlossen
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.SAFETYFINISHED) {
          this.SafetyFinishedMessage.emit(jmsg.ctxId);
        }

        // 4. Notifications
        if (jmsg.type === MessageTypes.NOTIFICATION && jmsg.entity === Entities.NOTIFICATION) {
          this.NotificationsReceived.emit(jmsg);
        }


        // 5.3. Update des Rezepts rückmelden
        if ((jmsg.type === MessageTypes.MESSAGE ||
          jmsg.type === MessageTypes.RESPONSE) &&
          jmsg.entity === Entities.RECIPE &&
          jmsg.subentity === 'updated') {
          this.RecipeUpdated.emit(jmsg);
        } else if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.RECIPE ) {
          // 5.2. Empfang bestätigen
          this.RecipeReceived.emit(jmsg);
        }



        // 6.2. Revision senden Cluster
        if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.REVISION) {
          this.ClusterRevisionReceived.emit(jmsg);
        }

        // 17.2. Cluster log message
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.LOGNOTIFICATION) {
          this.ClusterOnboardingLogMessage.emit(jmsg);
        }


      } else if (jmsg.context === ContextTypes.MODULE) {

        // 1.1. Cluster Request
        if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.CLUSTERREQUEST) {
          this.ClusterRequestResponse.emit(jmsg);
        }

        // 1.2. Cluster Reset
        if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.CLUSTERESET) {
          this.ClusterResetResponse.emit(jmsg);
        }

        // 1.3. Module stopped
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.MODULESSTOPPED) {
          this.ModulesStoppedMessage.emit(jmsg.ctxId);
        }

        // 1.4. Cluster config
        if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.CLUSTERCONFIG) {
          this.ClusterConfigResponse.emit(jmsg);
        }

        // 1.5. Module Init
        if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.MODULEINIT) {
          this.ModuleInitResponse.emit(jmsg);
        }

        // 1.6. Update Specification response
        if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.SPECIFICATION) {
          this.UpdateSpecificationResponse.emit(jmsg);
        }

        // 2.1. Module Mode changed
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.MODECHANGE) {
          this.ModuleModeChanged.emit(jmsg);
          this.sendAcknowledge(jmsg);
        }

        // 2.2. Wrong Module Mode
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.WRONGMODE) {
          this.WrongModuleModeReceived.emit(jmsg);
        }

        // 4. Monitoring
        if (jmsg.type === MessageTypes.NOTIFICATION && jmsg.entity === Entities.MONITORING) {
          this.MonitoringValuesReceived.emit(jmsg);
        }

        // 7.1. Modulstatus
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.AUTOSTATE) {
          this.ModuleStateChanged.emit(jmsg);
          this.sendAcknowledge(jmsg);
        }

        // 8. Modulstatus
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.CONNECTIONSTATE) {
          this.ModuleOnlineStateChanged.emit(jmsg);
        }

        // 10. Modulstatus
        if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.RECIPE) {
          this.ModuleConfigResponse.emit(jmsg);
        }

        // 11. NOT HALT TEST
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.EMERGENCYREQUIRED) {
          this.EmergencyTestStateChanged.emit(jmsg);
          this.sendAcknowledge(jmsg);
        }

        // 12. Status Änderung der internen Förderbänder
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.BELTSTATE) {
          this.InternalBeltConveyorChanged.emit(jmsg);
        }

        // 13.2. Nivellierungsinformationen
        if (jmsg.type === MessageTypes.RESPONSE && jmsg.entity === Entities.LEVELLING) {
          this.LevelingInformationReceived.emit(jmsg);
        }

        // 13.3. Nivellierung benötigt
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.LEVELLINGREQUIRED) {
          this.LevelingRequiredChanged.emit(jmsg);
        }

        // 14. Pop-up Mitteilung
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.POPUPNOTIFICATION) {
          this.ModuleMessageReceived.emit(jmsg);
        }

        // 17.1. Module log message
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.LOGNOTIFICATION) {
          this.ModuleOnboardingLogMessage.emit(jmsg);
        }

        // 18 Parameteränderung im Rezept
        if (jmsg.type === MessageTypes.MESSAGE && jmsg.entity === Entities.SETPARAMETER) {
          this.ModuleParameterChangeRequest.emit(jmsg);
        }


      } else if (jmsg.context === ContextTypes.DEVICE) {

        // NO MESSAGES

      } else if (jmsg.context === ContextTypes.MANAGEMENT) {

        // NO MESSAGES

      }

    } catch (e) {
    }



  }

  private sendMessage(msg: any): boolean {
    // console.log(msg);
    if (this.websocket.IsWebsocketConnected()) {
      this.websocket.SendMessage(JSON.stringify(msg));
      return true;
    } else {
      return false;
    }
  }

  // 2.3. Module Switch State Ack
  // 11.1. Emergency stop required Ack
  // 7.2. Module state Ack
  public sendAcknowledge(msg: any) {
    msg.type = MessageTypes.ACKNOWLEDGE;
    return this.sendMessage(msg);
  }

  // 1.1. Cluster request
  public requestCurrentModuleCluster(msgId: string, ctxId: string): boolean {
    const msg = {
      type: MessageTypes.REQUEST,
      entity: Entities.CLUSTERREQUEST,
      context: ContextTypes.MODULE,
      ctxId: ctxId,
      msgId: msgId
    };
    return this.sendMessage(msg);
  }

  // 1.2. Cluster Reset
  public requestClusterReset(msgId: string, ctxId: string) {
    const msg = {
      type: MessageTypes.REQUEST,
      entity: Entities.CLUSTERESET,
      context: ContextTypes.MODULE,
      ctxId: ctxId,
      msgId: msgId
    };

    return this.sendMessage(msg);
  }

  // 1.4. Cluster Config
  public sendClusterRequest(msgId: string,
                            ctxId: string,
                            clusterId: string,
                            recipeId: string,
                            requestedMaster: string,
                            recipe: any,
                            moduletypes: string[]) {
    const msg = {
      type: MessageTypes.REQUEST,
      entity: Entities.CLUSTERCONFIG,
      context: ContextTypes.MODULE,
      ctxId: ctxId,
      msgId: msgId,
      appversion: VERSION.raw,
      clusterId: clusterId,
      recipeId: recipeId,
      requestedMaster: requestedMaster,
      recipe: recipe,
      recipeModuleTypes: moduletypes
    };
    return this.sendMessage(msg);
  }

  // 1.5. Module Init
  public requestModuleInit(msgId: string, ctxId: string) {
    const msg = {
      type: MessageTypes.REQUEST,
      entity: Entities.MODULEINIT,
      context: ContextTypes.MODULE,
      ctxId: ctxId,
      msgId: msgId,
      device: NetworkDeviceTypes.TABLET,
      appversion: VERSION.raw
    };
    return this.sendMessage(msg);
  }

  // 1.7. Module Init Finished
  public sendModuleInitFinished(msgId: string, ctxId: string) {
    if (environment.demo === true) {
      return true;
    } else {
      const msg = {
        type: MessageTypes.MESSAGE,
        entity: Entities.MODULEINITFINISHED,
        context: ContextTypes.MODULE,
        ctxId: ctxId,
        msgId: msgId
      };
      const res = this.sendMessage(msg);
      this.websocket.Disconnect();
      return res;
    }
  }

  // 1.6. Update specification
  public updateModuleSpecification(msgId: string, ctxId: string, dms: any) {
    if (environment.demo === true) {
      return true;
    } else {
      const msg = {
        type: MessageTypes.MESSAGE,
        entity: Entities.SPECIFICATION,
        context: ContextTypes.MODULE,
        ctxId: ctxId,
        msgId: msgId,
        specification: dms
      };
      const res = this.sendMessage(msg);
      // console.log('disconnect');
      // this.websocket.Disconnect();
      return res;
    }
  }

  // 1.8. Cluster Init
  public requestClusterInit(msgId: string,
                            ctxId: string,
                            recipe: any,
                            ipAddresses: any[]) {
    const msg = {
      type: MessageTypes.REQUEST,
      entity: Entities.INIT,
      context: ContextTypes.CLUSTER,
      ctxId: ctxId,
      msgId: msgId,
      device: NetworkDeviceTypes.TABLET,
      appversion: VERSION.raw,
      recipe: recipe,
      ipaddresses: ipAddresses
    };
    return this.sendMessage(msg);
  }

  // 3.1. senden einer Servicekonfiguration alt
  public sendServiceConfigOld(msgId: string, ctxId: string, device: any) {
    if (environment.demo) {
      return true;

    } else {
      const msg = {
        type: MessageTypes.MESSAGE,
        entity: Entities.SERVICE,
        subentity: 'params',
        context: ContextTypes.MODULE,
        ctxId: ctxId,
        msgId: msgId,
        device: device
      };
      return this.sendMessage(msg);
    }
  }

  // 3.1. senden einer Servicekonfiguration
  public sendServiceConfig(msgId: string, ctxId: string, devices: any[]) {
    if (environment.demo) {
      return true;

    } else {
      const msg = {
        type: MessageTypes.MESSAGE,
        entity: Entities.SERVICE,
        subentity: 'params',
        context: ContextTypes.MODULE,
        ctxId: ctxId,
        msgId: msgId,
        devices: devices
      };
      return this.sendMessage(msg);
    }
  }

  // 4. Monitoring
  public subscribeModuleMonitoring(msgId: string, ctxId: string) {
    if (environment.demo === true) {
      const result = this.demoHandling.getMonitoringMessage(msgId);
      if (result) {
        setTimeout(() => this.MonitoringValuesReceived.emit(result), 3000);
      }


    } else {
      const msg = {
        type: MessageTypes.MESSAGE,
        entity: Entities.MONITORING,
        context: ContextTypes.MODULE,
        ctxId: ctxId,
        msgId: msgId,
        subentity: 'subscribe'
      };
      return this.sendMessage(msg);
    }

  }

  // 4. Monitoring
  public unsubscribeModuleMonitoring(msgId: string, ctxId: string) {
    if (environment.demo === true) {
      return true;
    } else {

      const msg = {
        type: MessageTypes.MESSAGE,
        entity: Entities.MONITORING,
        context: ContextTypes.MODULE,
        ctxId: ctxId,
        msgId: msgId,
        subentity: 'unsubscribe'
      };
      return this.sendMessage(msg);
    }
  }

  // 5. Send recipe
  public SendRecipe(msgId: string, ctxId: string, recipe: any) {
    const msg = {
      type: MessageTypes.MESSAGE,
      entity: Entities.RECIPE,
      context: ContextTypes.CLUSTER,
      ctxId: ctxId,
      msgId: msgId,
      recipe: recipe
    };
    return this.sendMessage(msg);
  }

  // 6.1. Revision abfragen
  public RequestClusterRevision(msgId: string, ctxId: string) {
    const msg = {
      type: MessageTypes.REQUEST,
      entity: Entities.REVISION,
      context: ContextTypes.CLUSTER,
      ctxId: ctxId,
      msgId: msgId
    };
    return this.sendMessage(msg);
  }

  // 9. Signalhorn Test
  public ModuleSignalTest(msgId: string, ctxId: string) {
    const msg = {
      type: MessageTypes.MESSAGE,
      entity: Entities.SIGNALTEST,
      context: ContextTypes.MODULE,
      ctxId: ctxId,
      msgId: msgId
    };
    return this.sendMessage(msg);
  }

  // 10. Request Module-Config
  public RequestModuleConfiguration(msgId: string, ctxId: string) {
    const msg = {
      type: MessageTypes.REQUEST,
      entity: Entities.RECIPE,
      context: ContextTypes.MODULE,
      ctxId: ctxId,
      msgId: msgId
    };
    return this.sendMessage(msg);
  }

  // 13.1. Nivellierung Abonnieren
  public SubscribeLeveling(msgId: string, ctxId: string) {
    const msg = {
      type: MessageTypes.REQUEST,
      entity: Entities.LEVELLING,
      context: ContextTypes.MODULE,
      ctxId: ctxId,
      msgId: msgId,
      subentity: 'subscribe'
    };
    return this.sendMessage(msg);
  }

  // 13.4. Nivellierung Deabonnieren
  public UnsubscribeLeveling(msgId: string, ctxId: string) {
    const msg = {
      type: MessageTypes.REQUEST,
      entity: Entities.LEVELLING,
      context: ContextTypes.MODULE,
      ctxId: ctxId,
      msgId: msgId,
      subentity: 'unsubscribe'
    };
    return this.sendMessage(msg);
  }


  // DEMO AREA

  public demoChangeModuleState(msg: any) {
    this.ModuleStateChanged.emit(msg);
  }

  public demoModeSwitch(msg: any) {
    this.ModuleModeChanged.emit(msg);
  }

  public demoEmergency(msg: any) {
    this.EmergencyTestStateChanged.emit(msg);
  }

  public demoParameterChange(msg: any) {
    this.ModuleParameterChangeRequest.emit(msg);
  }

  public onDemoNotification(module: CustomerModule) {

    var notifications = [];

    for(var component of module.Module.Components) {
      notifications.push({
        ctxId: component.PlcKey,
        context: 'device',
        type:1,
        code: '0005'
      });

      notifications.push({
        ctxId: component.PlcKey,
        context: 'device',
        type:2,
        code: '0005'
      });
    }

    const msg = {
      type: 'notification',
      context: 'cluster',
      entity: 'notification',
      ctxId: 'xxxx',
      module: {
        serialnumber: module.SerialNumber,
        notifications: notifications
      }
    };

    this.NotificationsReceived.emit(msg);
  }

}
