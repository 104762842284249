import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ModuleComponent} from '../../../classes/module-component';
import {ComponentVariable} from '../../../classes/component-variable';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';

@Component({
  selector: 'app-component-monitoring',
  templateUrl: './component-monitoring.component.html',
  styleUrls: ['./component-monitoring.component.css']
})
export class ComponentMonitoringComponent implements OnInit {

  private _ModeSw: ModeSwitch;
  private _inventoryView = false;
  OwnVisible = false;

  @Input() set inventoryView(value: boolean) {
    this._inventoryView = value;
  }
  @Input() device: ModuleComponent;
  @Input() set switchModeString(value: string) {

    if (value === null) {
      this._ModeSw = ModeSwitch.NONE;
    } else if (value === 'service') {
      this._ModeSw = ModeSwitch.SERVICE;
    } else {
      this._ModeSw = ModeSwitch.AUTOMATIC;
    }
  }
  @Input() set switchMode(value: ModeSwitch) {
    this._ModeSw = value;
  }

  @Output() OnSendToModule = new EventEmitter<ModuleComponent>();

  get switchMode(): ModeSwitch {
    return this._ModeSw;
  }

  constructor(public moduleHandling: ModuleHandlingService,
              public translate: BluectrlTranslateService) {
    this.moduleHandling.OnModuleDeviceSelected.subscribe(this.ModuleDeviceSelected.bind(this));
  }

  ngOnInit(): void {

  }

  GetMonitorValues(): ComponentVariable[] {
    return this.device.Monitorings.filter(ex => ex.Visible === true);
  }

  public ShowConfig(deviceValue: ComponentVariable): boolean {

    return false;
  }

  public ModuleDeviceSelected(moduleDevice: ModuleComponent) {
    this.OwnVisible = moduleDevice.PlcKey === this.device.PlcKey;
  }

  public getValueClasses(moduledevicevalue: ComponentVariable): any {
    const classes = {
      'darkVersion': this.moduleHandling.CurrentViewMode === 0 || this.moduleHandling.CurrentViewMode === 4,
      'whiteVersion': this.moduleHandling.CurrentViewMode === 1,
      'inventoryTheme': this.moduleHandling.CurrentViewMode === 3,
      'array-value': moduledevicevalue.CurrentValue && typeof moduledevicevalue.CurrentValue === 'object'
    };
    return classes;
  }

  public GetCurrentValue(moduledevicevalue: ComponentVariable): any {
    if (moduledevicevalue.States.length > 0) {
      if (moduledevicevalue.CurrentValue != null && moduledevicevalue.CurrentValue !== undefined) {
        const fnd = moduledevicevalue.States.find(ex => ex.State === moduledevicevalue.CurrentValue);
        if (fnd) {
          const translated =  this.translate.GetTranslation(fnd.GetTranslationID());
          if (translated !== fnd.GetTranslationID()) {
            return translated;
          } else {
            return fnd.GetName();
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      // Handle array and object values
      if (Array.isArray(moduledevicevalue.CurrentValue) || (typeof moduledevicevalue.CurrentValue === 'object' && moduledevicevalue.CurrentValue !== null)) {
        // return `Text: ID\n${formattedString}`;
        const MAX_LINE_LENGTH = 24;

        const wrapText = (str: string, maxLength: number): string[] => {
            let lines: string[] = [];
        
            if (str.length <= maxLength) return [str]; // No need to wrap
        
            let remainder = str.length % maxLength;
            if (remainder === 0) remainder = maxLength;
        
            lines.push(str.slice(0, remainder)); // First line (remainder)
            str = str.slice(remainder);
        
            while (str.length > 0) {
                lines.push(str.slice(0, maxLength)); // Full lines
                str = str.slice(maxLength);
            }
        
            return lines;
        };
        
        // Example Object Processing
        const entries = Object.entries(moduledevicevalue.CurrentValue)
            .filter(([_, v]) => v !== 0)
            .map(([k, v]) => [wrapText(String(v), MAX_LINE_LENGTH), k] as [string[], string]); // Force correct types
        
        // Determine max widths
        const maxTextLength = Math.max(...entries.flatMap(([v, _]) => v.map(l => l.length)));
        const maxIDLength = Math.max(...entries.map(([_, k]) => k.length));
        
        const formattedString =
            "Text".padStart(maxTextLength) + ": " + "ID".padStart(maxIDLength) + "\n" +
            entries.map(([vLines, k]) =>
                (Array.isArray(vLines) ? vLines : [String(vLines)]) // Ensure vLines is always an array
                .map((line, i) =>
                  i < vLines.length - 1 
                        ? line.padStart(maxTextLength) + "-" + " ".repeat(maxIDLength + 2) // Add "-" for continuation
                        : line.padStart(maxTextLength) + ": " + k.padStart(maxIDLength) // Last line with ": ID"
                ).join("\n")
            ).join("\n");
        
        return formattedString;
      }
      return moduledevicevalue.CurrentValue;
    }
  }

  public GetUnitTranslation(moduledevicevalue: ComponentVariable): string {
    if (moduledevicevalue.getUnitId()) {
      return this.translate.GetTranslation(moduledevicevalue.getUnitId());
    } else {
      return '';
    }
  }

}
